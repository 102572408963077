import { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { useRouter } from 'next/router'
import { UtmContext } from '../../contexts/UtmContext';
import Link from '../Link';

import logo from '../../public/img/logo.png'
import WhatappSvg from '../../public/svg/whatsapp.svg'
import InstagramSvg from '../InstagramSvg'

function Header({ siteSetting }) {
  console.log('Header', siteSetting);
  const utmParams = useContext(UtmContext);
  const [hidden, setHidden] = useState(false);
  const mobileMenuRef = useRef(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const router = useRouter();

  const openMobileMenu = () => {
    setMobileMenuOpen(true);
    document.body.classList.add('locked');
    mobileMenuRef.current.style.display = '';
    document.body.offsetHeight;
  }

  const closeMobileMenu = useCallback(() => {
    setMobileMenuOpen(false);
    document.body.classList.remove('locked');
    setTimeout(() => {
      mobileMenuRef.current.style.display = 'none';
    }, 160);
  }, []);

  const goToSection = (sectionId) => {
    closeMobileMenu();
  
    const hrefWithQuery = `/#${sectionId}${utmParams}`;
  
    router.push(hrefWithQuery, undefined, { scroll: false }).then(() => {
      // Manually scroll to the element
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    });
  };

  useEffect(() => {
    const shopSection = document.getElementById('shop');

    if (shopSection) {
      const handleScroll = () => {
        setHidden(window.scrollY >= (shopSection.offsetTop - 100) ? true : false);
      };

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      if (mobileMenuOpen) {
        closeMobileMenu();
      }
    };
  
    router.events.on('routeChangeStart', handleRouteChange);
  
    // Clean up the event listener when the component is unmounted or before it re-renders
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [mobileMenuOpen, closeMobileMenu, router.events]);

  return (
    <div className="sm:h-vw-28 h-vw-36">
      <header
        className="desktop-only container-main w-full fixed top-vw-4 left-1/2 z-[80] transform -translate-x-1/2 transition-transform"
        style={{ '--tw-translate-y': hidden ? 'calc(-100% - clamp(8px, 1.25vw, 24px))' : '0vw' }}
      >
        <div className="flex h-full bg-gray/50 rounded-vw-4xl py-vw-2 pl-vw-6 pr-vw-2" style={{ backdropFilter: 'blur(24px)' }}>
          <Link href="/" className="flex-shrink-0">
            <img className="w-vw-24" src={logo.src} alt="new form - logo" />
          </Link>

          <div className="centered-y flex-grow px-vw-10 gap-9">
            <button className="link-main text-vw-base text-left" aria-label="How does it work?" onClick={() => goToSection('how-it-works')}>
              How does it work?
            </button>

            <Link
              href="/blog"
              className="link-main text-vw-base text-left" aria-label="Blog" >
              Blog
            </Link>

            <Link
              href="/gallery"
              className="link-main text-vw-base text-left" aria-label="Cases" >
              Cases
            </Link>
          </div>

          <div className="flex flex-shrink-0 items-center">
            <a className="group mr-vw-12 -mt-vw-6" aria-label="Email" href={`mailto:${siteSetting.email}`}>
              <span className="text-vw-xs text-translucent">
                mail
              </span>

              <p className="group-hover:underline text-vw-base">
                {siteSetting.email}
              </p>
            </a>

            <div className="items-start mr-vw-8 -mt-vw-6">
              <span href={siteSetting.phone.rfc3966} className="text-vw-xs text-translucent">
                phone
              </span>

              <div className="text-vw-base">
                <a className="hover:underline" aria-label="Phone" href={siteSetting.phone.rfc3966}>
                  {siteSetting.phone.international}
                </a>

                {siteSetting.whatsappUrl && (
                  <a
                  href={
                    siteSetting.whatsappText 
                      ? `${siteSetting.whatsappUrl}?text=${encodeURIComponent(siteSetting.whatsappText)}`
                      : siteSetting.whatsappUrl
                  }
                    target="_blank"
                    rel="noreferrer noopener"
                    aria-label="WhatsApp" 
                    className="inline-block ml-vw-2.5 -mb-vw-1.5"
                  >
                    <WhatappSvg className="w-vw-6" />
                  </a>
                )}

                {siteSetting.instagramUrl && (
                  <a
                    href={siteSetting.instagramUrl}
                    target="_blank"
                    rel="noreferrer noopener"
                    aria-label="Instagram" 
                    className="inline-block ml-vw-2.5 -mb-vw-1.5"
                  >
                    <InstagramSvg id="header1" className="w-vw-6" />
                  </a>
                )}
              </div>
            </div>

            <button className="big-ass-button" aria-label="Shop" onClick={() => goToSection("shop")}>
              {"Let's get started"}
            </button>
          </div>
        </div>
      </header>

      <header className="mobile-only container-main bg-gray fixed top-0 left-1/2 z-[80] -translate-x-1/2">
        <div
          className="flex justify-between items-stretch h-vw-32 relative z-10"
          style={{ backdropFilter: 'blur(24px)' }}
        >
          <Link href="/" className="flex items-center flex-shrink-0">
            <img className="w-vw-44" src={logo.src} aria-label="Home" alt="New Form" />
          </Link>

          <button className="flex items-center pl-vw-10" aria-label="Menu" onClick={() => mobileMenuOpen ? closeMobileMenu() : openMobileMenu()}>
            {mobileMenuOpen ? (
							<svg className="w-vw-9" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" clipRule="evenodd" d="M6.87596 9L0 15.876L2.12404 18L9 11.124L15.876 18L18 15.876L11.124 9L18 2.12404L15.876 0L9 6.87596L2.12404 0L0 2.12404L6.87596 9Z" fill="#353535"/>
							</svg>
            ) : (
              <svg className="w-vw-9" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="18" height="3" fill="#353535" />
                <rect y="7.5" width="18" height="3" fill="#353535" />
                <rect y="15" width="18" height="3" fill="#353535" />
              </svg>
            )}
          </button>
        </div>

        <div
					ref={mobileMenuRef}
          className="h-screen w-full bg-gray absolute top-0 left-0 touch-none transition-[clip-path] pt-vw-40"
					style={{
						display: 'none',
						clipPath: mobileMenuOpen ? 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)' : 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)'
					}}
				>
					<div className="container-main flex flex-col items-start h-full pt-vw-40">
						<button aria-label="How does it work?" onClick={() => goToSection("how-it-works")} className="text-vw-6xl font-bold">
							How it works
						</button>

            <button aria-label="Blog" className="text-vw-6xl font-bold mt-vw-14" >
              <Link href="/blog">
                Blog
              </Link>
            </button>

						<button aria-label="Shop" onClick={() => goToSection("shop")} className="text-vw-6xl font-bold mt-vw-14 ">
							Catalogue
						</button>

            <button aria-label="Cases" className="text-vw-6xl font-bold mt-vw-14" >
              <Link href="/gallery">
                Cases
              </Link>
            </button>

						<div className="mt-auto pb-vw-56 w-full">
              <a className="block mt-vw-14" aria-label="Email" href={`mailto:${siteSetting.email}`}>
                <span className="text-vw-3xl text-translucent">
                  mail
                </span>
                <p className="text-vw-4xl">
                  {siteSetting.email}
                </p>
              </a>

              <a className="block mt-vw-16" aria-label="Phone" href={siteSetting.phone.rfc3966}>
                <span className="text-vw-3xl text-translucent">
                  phone
                </span>

                <p className="text-vw-4xl mt-vw-1">
                    {siteSetting.phone.international}
                </p>
              </a>

              {(siteSetting.whatsappUrl || siteSetting.instagramUrl) && (
                <p className="block mt-vw-16 text-center">
                  {siteSetting.whatsappUrl && (
                    <a
                    href={
                      siteSetting.whatsappText 
                        ? `${siteSetting.whatsappUrl}?text=${encodeURIComponent(siteSetting.whatsappText)}`
                        : siteSetting.whatsappUrl
                    }
                      target="_blank"
                      rel="noreferrer noopener"
                      aria-label="WhatsApp" 
                      className="inline-block ml-vw-3 -mb-vw-1.5"
                    >
                      <WhatappSvg className="w-vw-24" />
                    </a>
                  )}

                  {siteSetting.instagramUrl && (
                    <a
                      href={siteSetting.instagramUrl}
                      target="_blank"
                      rel="noreferrer noopener"
                      aria-label="Instagram" 
                      className="inline-block ml-vw-24 -mb-vw-1.5"
                    >
                      <InstagramSvg id="header2" className="w-vw-24" />
                    </a>
                  )}
                </p>
              )}
						</div>
					</div>
        </div>
      </header>
    </div>
  )
}

Header.propTypes = {};

Header.defaultProps = {};


export default Header;
